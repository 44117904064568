<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action </vs-th>
                <vs-th sort-key="code">PO Data </vs-th>
                <vs-th sort-key="code">PO Type</vs-th>
                <vs-th sort-key="code">Warehouse</vs-th>
                <vs-th sort-key=""></vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                    <vs-td></vs-td>
                <vs-th sort-key="">PO Value</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button size="small" color="primary" icon-pack="feather" icon="icon-edit" title="Edit"
                                style="margin-right: 2px" @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-printer"
                                style="margin-right: 2px" title="Print Preview" @click="handleDetail(tr.id,false)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                                title="Request Approval" @click="handleApprovalRequest(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.code }}</div>
                            <div v-if="tr.external_code">External Code : {{ tr.external_code }}</div>
                            <div>Date : {{ formatDate(tr.posting_date) }}</div>
                            <div>Term : {{ tr.payment_term_name }}</div>
                            <div>Eta : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.purchase_type.name }}</div>
                        <div v-if="tr.so_code">SO Code : {{ tr.so_code }}</div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.warehouse.code }}</div>
                            <div>Name : {{ tr.warehouse.name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="poValue">
                        <div class="flex flex-col content-center">
                            <div>Currency : {{ tr.currency_code }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Sub Total</span>{{ isNaN((tr.total - tr.tax)) ? 0 : formatLocalNumber((tr.total - tr.tax)) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Sub Total</span>{{ isNaN((tr.total - tr.tax)) ? 0 : formatLocalNumber((tr.total - tr.tax)) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Total</span>{{ formatLocalNumber(tr.total) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Total</span>{{ formatLocalNumber(tr.total) }}</div>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            // console.log(this.table.length);
            this.$vs.loading();
            this.$http
                .get(this.$store.state.purchaseOrder.baseUrlPath, {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        supplier_id: this.supplierId,
                        po_type_ids: this.poTypeId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        this.table.data = resp.data.rows?resp.data.rows:[];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleApprovalRequest(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                title: `Confirm`,
                text: "Are you sure to send request approval for this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.patch(`${this.$store.state.purchaseOrder.baseUrlPath}/approval/${id}/submit`)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was success request to approve, check waiting approval tab.",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.getData();
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        });
                },
            });
        },
        handleEdit(id) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-inquiry",
                params: { id: id },
            });
        },
        handleDetail(id, print) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-detail",
                params: { id: id },
                query: {print:print},
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
    },
};
</script>

<style>
.d-inline-flex-b{
    display: inline-flex; 
    justify-content: space-between;
}
</style>
  